@font-face {
  font-family: "CedarvilleCursive";
  src: local("CedarvilleCursive"),
    url("./CedarvilleCursive-Regular.ttf") format("truetype");
  font-weight: bold;
}
.cursive {
  font-family: "CedarvilleCursive" !important;
}
.yellow {
  color: #ff0;
}
.default-template {
  min-height: 100vh;
  min-width: 100vw;
  width: 100%;
  height: 100%;
  padding-bottom: 35px;
  background-color: rgba(0,0,0,0.9);
  .default-template__background {
    background-image: url("./reptile.png");
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.75;
    min-height: 100vh;
    min-width: 100vw;
    width: 100%;
    position: fixed;
    top: 0;
  }
  .default-template__header {
    background-color: rgba(0, 0, 0, 1);
    opacity: 0.9;
    .header__menu-icon {
      color: #fff;
    }
  }
  .home-link {
    display: flex;
    text-decoration: none;
    .default-template__logo {
      img {
        height: 45px;
      }
    }
  }
  .default-template__title {
    margin-bottom: 0px;
    line-height: 40px;
    vertical-align: middle;
    height: 40px;
    font-size: 30px;
  }
  .default-template__subtitle {
    margin-top: 0;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    position: relative;
    top: -5px;
  }
  .default-template__content {
    padding-top: 80px;
    min-height: calc(100vh - 80px);
    flex: 1;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.side-menu {
  .side-menu__list {
    a {
      display: flex;
      flex: 1;
      text-decoration: none;
    }
  }
}